import NotFound from "@public/assets/svg/notFound.svg";
import Button from "@components/Button";
import router from "next/router";
const CustomNotFoundPage = () => {
  return (
    <div className="flex flex-col items-center justify-center h-full space-y-4 font-serif text-graniteGray">
      <h4 className="font-serif text-bold">Kho gaye hum kahan! 🙈</h4>
      <NotFound />
      <div className="flex flex-col space-y-5 ">
        <p className="text-lg">Let us help you to find your betterHalf</p>
        <Button label="Yes, Please!" className="text-xl" onClick={() => router.push("/")}></Button>
      </div>
    </div>
  );
};

export default CustomNotFoundPage;
